import '@fontsource/montserrat'
import '@fontsource/montserrat/600.css'
import './src/css/index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const onClientEntry = () => {
  if (typeof window === 'undefined') return
  const script = document.createElement('script')
  script.src = 'https://plausible.io/js/script.js'
  script.setAttribute('data-domain', 'cloudgateway.co.uk')
  script.defer = true
  document.head.appendChild(script)
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!location) return
  
  if (location.state) {
    location.state.referrer = prevLocation?.pathname || null
  }

  if (typeof window === 'undefined') return

  const codeBlocks = document.querySelectorAll('.code')
  if (!codeBlocks?.length) return

  codeBlocks.forEach(codeBlock => {
    const scriptElement = codeBlock.querySelector('script[type="text/javascript"]')
    if (!scriptElement?.innerHTML) return

    try {
      setTimeout(() => {
        new Function(scriptElement.innerHTML)()
      }, 1000)
    } catch (error) {
      console.error('Error executing JavaScript code:', error)
    }
  })
}

export const shouldUpdateScroll = ({ routerProps }) => {
  if (!routerProps?.location?.pathname) return true
  
  const scrollToTopRoutes = ['/knowledge-centre/']
  return scrollToTopRoutes.indexOf(routerProps.location.pathname) === -1
}